import _i18n from "i18next";
import { I18nextProvider as Provider } from "react-i18next";
import en from "./lng/en.json";
import hu from "./lng/hu.json";

export const i18n = _i18n.createInstance();

void i18n.init({
    ns: [
        "dashboard",
        "measures",
        "onboarding",
        "doctor",
        "preop",
        "pregnancy",
        "defaultMessages",
        "common",
    ],
    defaultNS: "common",
    resources: {
        en,
        hu,
    },
    fallbackLng: "hu",
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

export { Provider as I18nextProvider };
